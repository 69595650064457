<template>
  <el-dialog
      title="图片裁剪"
      width="50%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close"
      center>
    <div style="height: 46vh;overflow: auto">
      <el-row>
        <el-col :span="16" style="margin-top: 20px">
          <div class="cropper" style="text-align:center">
            <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :maxImgSize="option.maxImgSize"
                @realTime="realTime"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
            ></vueCropper>
          </div>
        </el-col>
        <el-col :span="8" style="margin-top: 20px">
          <div v-html="previewHTML"></div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="cropper">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "styleImageUpload",
  created() {
    this.option.maxImgSize = this.file.size
    this.originalName = this.file.name;
    this.option.img = URL.createObjectURL(this.file.raw)
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    file: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      states: this.state,
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 230,
        autoCropHeight: 360,
        centerBox: false,
        high: false,
        cropData: {},
        enlarge: 1,
        mode: 'contain',
        maxImgSize: 3000,
        limitMinSize: [100, 120]
      },
      previews: {},
      previewHTML: "",
      originalName: "",
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //实时预览
    realTime (data) {
      this.previewHTML = data.html
    },
    // 图片裁剪
    cropper() {
      if (this.originalName === "") {
        this.$message({
          showClose: true,
          message:  "请先选择图片",
          type: 'warning',
          duration: 1500,
        })
        return
      }
      this.$refs.cropper.getCropBlob(data => {
        var result = new File([data], this.originalName, {type: 'image/jpeg', lastModified: Date.now()});
        this.$emit("getCropperImage", result)
        this.close()
      })
    },
  },
}
</script>

<style scoped>
.cropper {
  width: 500px;
  height: 360px;
}
</style>