<template>
  <div>
    <el-row>
      <el-col :span="10">
        <el-form label-width="120px" :model="form" :rules="rules" ref="form">
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式类型" prop="styleType">
                <el-select v-model="form.styleType" placeholder="款式类型" style="width: 100%" @change="styleTypeChange">
                  <el-option
                      v-for="item in styleTypeArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="款式品牌">
                <el-select v-model="form.brandId" placeholder="款式品牌" style="width: 100%">
                  <el-option
                      v-for="item in brandArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="店铺款式编号" prop="styleName">
                <el-input v-model="form.styleName" placeholder="建议数字格式: 0001 0002" :readonly="autoStyleName">
                    <template slot="append">
                      <el-tooltip class="item" effect="dark" content="店铺内部编号" placement="top-start">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-checkbox style="margin-top:10px;margin-left:30px" v-model="autoStyleName">自动编号</el-checkbox>
            </el-col>
          </el-row>
<!--          <el-row>-->
<!--            <el-col :span="11">-->
<!--              <el-form-item label="品牌款式编号">-->
<!--                <el-input v-model="form.factoryNumber" placeholder="品牌款式编号">-->
<!--                  <template slot="append">-->
<!--                    <el-tooltip class="item" effect="dark" content="品牌官方编号" placement="top-start">-->
<!--                      <i class="el-icon-question"></i>-->
<!--                    </el-tooltip>-->
<!--                  </template>-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="11">-->
<!--              <el-form-item label="品牌款式名称">-->
<!--                <el-input v-model="form.factoryName" placeholder="品牌款式名称">-->
<!--                  <template slot="append">-->
<!--                    <el-tooltip class="item" effect="dark" content="品牌官方名称" placement="top-start">-->
<!--                      <i class="el-icon-question"></i>-->
<!--                    </el-tooltip>-->
<!--                  </template>-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式名称" prop="styleAlias">
                <el-input v-model="form.styleAlias" placeholder="请输入款式名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="采购日期" prop="purchaseDate">
                <el-date-picker type="date" placeholder="采购日期"
                                v-model="form.purchaseDate"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="款式标签">
                <el-select v-model="form.styleLabels" filterable placeholder="款式标签" style="width: 100%" multiple>
                  <el-option
                      v-for="item in styleLabelArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="图片类型" prop="imageType">
                <el-select v-model="form.imageType" placeholder="图片类型" clearable>
                  <el-option
                      v-for="item in styleImageArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="第一件婚纱">
                <el-switch
                    v-model="firstClothes"
                    active-color="#409eff"
                    inactive-color="#f1f3f4"
                    :width="50">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="firstClothes">
              <el-form-item label="婚纱尺寸" prop="clothesSize">
                <el-select v-model="form.clothesSize" placeholder="婚纱尺寸">
                  <el-option
                      v-for="item in clothesSizeArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="firstClothes">
            <el-col :span="11">
              <el-form-item label="所在店铺" prop="clothesShop">
                <el-select v-model="form.clothesShop" placeholder="店铺"  @change="queryClothesPosition" ref="shop">
                  <el-option
                      v-for="item in clothesShopArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="所在位置" prop="clothesPosition">
                <el-select v-model="form.clothesPosition" placeholder="婚纱位置" clearable>
                  <el-option
                      v-for="item in clothesPositionArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="款式介绍">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    v-model="form.styleInfo">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="适合身形">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    v-model="form.styleFit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="不适合身形">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    v-model="form.styleNoFit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row>
          <el-col :push="17">
            <el-button type="warning" @click="reset">重置</el-button>
            <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="10" :push="1">
        <el-divider content-position="left">上传款式图片</el-divider>
        <el-button type="primary" @click="imageUploadOpen" icon="el-icon-scissors" v-if="false">裁剪图片</el-button>
        <el-row style="margin-top: 30px">
          <div>
            <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleAvatarChangeIcon">
              <img v-if="styleImage.imageUrl" :src="styleImage.imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <style-image-upload v-if="imageUploadState" :state="imageUploadState" :file="styleImage.file" @close="imageUploadClose" @getCropperImage="getCropperImage"></style-image-upload>
  </div>
</template>

<script>
import styleImageUpload from "@/components/image/styleImageUpload";
export default {
  name: "style-add",
  components: {
    styleImageUpload
  },
  created() {
    this.pageInit()
  },
  data() {
    return {
      form: {
        styleType: "",
        styleAlias: "",
        styleName: "",
        purchaseDate: new Date(),
        brandId: "",
        styleLabels: [],
        clothesSize: "",
        imageType: "",
        clothesShop: "",
        clothesPosition: "",
        factoryNumber: "",
        factoryName: "",
        styleInfo: "",
        styleFit: "",
        styleNoFit: "",
        styleImage: "",
        empId:JSON.parse(localStorage.getItem("emp")).id,
      },
      rules: {
        styleType:[{required: true, message: '请选择款式类型项目', trigger: 'change'}],
        styleAlias:[{required: true, message: '请输入款式名称', trigger: 'blur'}],
        styleName:[{required: true, message: '请输入款式编号', trigger: 'blur'}],
        purchaseDate:[{required: true, message: '请选择采购日期', trigger: 'change'}],
        clothesSize:[{required: true, message: '请选择婚纱尺寸', trigger: 'change'}],
        clothesShop:[{required: true, message: '请选择所在店铺', trigger: 'change'}],
        clothesPosition:[{required: true, message: '请选择所在位置', trigger: 'change'}],
      },
      autoStyleName: true,
      styleTypeArray: [],
      tenantCrop: localStorage.getItem("tenantCrop"),
      brandArray: [],
      styleLabelArray: [],
      clothesSizeArray: [
        {value: "XS"},{value: "S"},
        {value: "M"},{value: "L"},
        {value: "XL"},{value: "XXL"},
        {value: "XXXL"},
      ],
      styleImageArray: [],
      firstClothes: true,
      clothesShopArray: [],
      clothesPositionArray: [],
      imageUploadState: false,
      styleImage: {
        imageUrl: "",
        file: {},
      },
      loading: false,
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryStyleTypeIds().then(response => {
        this.styleTypeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryBrandIds().then(response => {
        this.brandArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryLabelIds(this.$labelType.style).then(response => {
        this.styleLabelArray = response.data.data
      })
      this.$selectUtils.queryStyleImageTypes(0).then(response => {
        this.styleImageArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.clothesShopArray = JSON.parse(response.data.data);
      })
    },
    styleTypeChange(val) {
      if (this.autoStyleName) {
        this.$axios({
          method: "GET",
          url: "/style/queryStyleName",
          params: {
            styleType: val,
            tenantCrop: this.tenantCrop
          }
        }).then(response => {
          let name = response.data.data + "";
          let styleNameLen = name.length
          switch (styleNameLen) {
            case 1:
              name = "000" + name;
              break;
            case 2:
              name = "00" + name;
              break;
            case 3:
              name = "0" + name;
              break;
          }
          this.form.styleName = name
        })
      }
    },
    queryClothesPosition(shopId) {
      this.$selectUtils.queryPositionIdsByShop(shopId).then(response => {
        this.clothesPositionArray = JSON.parse(response.data.data)
        if (localStorage.getItem("tenantCrop") == '2a31c23a-c178-441614928053489') {
          if (this.form.clothesShop == 59) {
            this.clothesPositionArray.forEach(s => {
              if (s.name.indexOf("新款入库区域") != -1) {
                this.form.clothesPosition = s.value
                return
              }
            })
          } else {
            this.clothesPositionArray.forEach(s => {
              if (s.name.indexOf("收件待定") != -1) {
                this.form.clothesPosition = s.value
                return
              }
            })
          }
        }
      })
    },
    reset() {
      Object.keys(this.form).forEach(key=>{this.form[key]=''})
    },
    imageUploadOpen() {
      this.imageUploadState = true
    },
    imageUploadClose() {
      this.imageUploadState = false
    },
    getCropperImage(file) {
      this.styleImage.imageUrl = URL.createObjectURL(file)
      this.styleImage.file = file
    },
    submit() {
      console.log(this.form)
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true
          this.uploadStyleImage().then(value => {
            if (!value) {
              this.$message({
                showClose: true,
                message: "图片上传发生错误,请检查后重新上传",
                type: 'error',
                duration: 1500,
              })
            } else {
              this.form.tenantCrop=this.tenantCrop;
              this.$axios({
                method: "POST",
                url: "/style/saveStyle",
                data: this.form
              }).then(response => {
                let flag = response.data.code === 200
                this.$message({
                  showClose: true,
                  message: flag ? "款式添加成功" :response.data.msg,
                  type: flag? 'success' :'error',
                  duration: 1000,
                })
                if (flag) {
                  this.reset()
                  this.styleImage.imageUrl = ""
                  this.styleImage.file = {}
                }
                this.loading = false
              })
            }
          })
        }
      })
    },
    uploadStyleImage: function () {
      return new Promise((resolve, reject) => {
        if (this.styleImage.imageUrl) {
          this.$upload.clothesImageUpload(this.styleImage.file)
              .then(response => {
                let data = response.data
                if (data.code === 200) {
                  this.form.styleImage = data.data
                  resolve(true)
                } else {
                  reject(false)
                }
              })
        } else {
          resolve(true)
        }
      })
    },
    handleAvatarChangeIcon(file) {
      this.styleImage.file = file.raw
      this.rules.imageType = [{required: true, message: '请选择图片类型', trigger: 'change'}]
      this.styleImage.imageUrl = URL.createObjectURL(file.raw)
      let wareArr=this.styleImageArray.find(k=>k.name==='仓库图');
      if (wareArr!==undefined){
        this.form.imageType=wareArr.value;
      }
    },
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 280px;
  height: 410px;
  display: block;
}
</style>